import React, { useEffect, useState } from 'react';
import { Col, Row, Calendar, Spin, Card, Typography, Button, Table, Select, Radio, Switch } from 'antd';
import { Table as NewTable } from '@app/components/common/Table/Table';
import moment, { Moment } from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { doGetBookings, doGetTodaysBooking, doUpdateTodaysBooking, saveDate } from '@app/store/slices/bookingSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import _ from 'lodash';
import { useResponsive } from '@app/hooks/useResponsive';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { EditSlotsForm } from '@app/components/forms/EditSlotsForm/EditSlotsForm';
import * as S from './Calendar.styles';

const getFirstDateAndLastDateOnThePanel = (date: Moment, mode: CalendarMode) => {
  const firstDate1 = moment(date).startOf('month').format('YYYY-MM-DD');
  let lastDate1 = '';
  if (mode === 'year') {
    lastDate1 = moment(date).endOf('year').format('YYYY-MM-DD');
  } else {
    lastDate1 = moment(date).endOf('month').format('YYYY-MM-DD');
  }

  // const firstDate = moment(date).startOf('month');
  // const lastDate = moment(date).endOf('month');

  // const firstDateDay = firstDate.day();
  // firstDate.subtract(firstDateDay, 'days');
  // lastDate.add(42 - Number(lastDate.format('DD')) - firstDateDay, 'days');

  return {
    firstDate1,
    lastDate1,
  };
};
const { Column } = Table;
function App() {
  const bookings = useAppSelector((state) => state.booking);
  const bookingsData: any = bookings.bookingsData;
  const todaysBookingData: any = bookings.todaysBookingData;
  const totalSlots: any = bookings.totalSlots;
  const user = useAppSelector((state) => state.auth);
  const UserData: any = user.UserData;
  const navigate = useNavigate();
  const { isTablet } = useResponsive();
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [loadingTodayBooking, setLoadingTodayBooking] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const dispatch = useAppDispatch();
  const { Title } = Typography;

  // useEffect(() => {
  //   // console.log(bookingsData);
  //   if (_.isEmpty(bookingsData)) {
  //     setFilters([]);
  //     return;
  //   } else {
  //     const x: any = [];
  //     bookingsData.map((item: any) =>
  //       x.push({
  //         text: item.fullName,
  //         value: item.fullName,
  //       }),
  //     );
  //     setFilters(x);
  //   }
  // }, [bookingsData]);

  const getMow = () => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    const d = {
      to: endOfMonth,
      from: startOfMonth,
    };
    setLoading(true);
    dispatch(doGetBookings(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  };
  const getTodaysBookingData = () => {
    const today = moment().format('YYYY-MM-DD');
    setLoadingTodayBooking(true);
    dispatch(doGetTodaysBooking({ date: today }))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoadingTodayBooking(false));
  };

  useEffect(() => {
    getMow();
    getTodaysBookingData();
  }, []);

  const onSelect = (newValue: Moment) => {
    // console.log(newValue)
    dispatch(saveDate(moment(newValue).format('YYYY-MM-DD')));
    setDate(newValue);
    navigate('/scheduleData');
    // setSelectedValue(newValue);
  };
  const handlePanelChange = (date: Moment, mode: CalendarMode) => {
    setLoading(true);
    const { firstDate1, lastDate1 } = getFirstDateAndLastDateOnThePanel(date, mode);
    const d = {
      from: firstDate1,
      to: lastDate1,
    };
    dispatch(doGetBookings(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  };

  // const getCellData = (x: any) => bookingsData.find((sI: any) => sI.checkIn.includes(moment(x).format('YYYY-MM-DD')));
  const getMonthCheckIn = (x: any) => {
    let count = -1;
    bookingsData.responseData &&
      bookingsData.responseData.map((sI: any) => {
        moment(sI.date).isSame(moment(x), 'month') ? (count = sI.free) : null;
      });
    return count;
  };
  const getCheckIn = (x: any) => {
    let count = -1;
    bookingsData.responseData &&
      bookingsData.responseData.map((sI: any) => {
        moment(sI.date).isSame(moment(x), 'day') ? (count = sI.free) : null;
      });
    return count;
  };

  const handleCellRender = (x: any) => {
    const checkIn = getCheckIn(x);
    // const checkOut = getCheckOut(x);
    return isTablet ? (
      <div onClick={() => onSelect(x)}>
        {checkIn > -1 && (
          <div style={{ display: 'flex', justifyContent: 'center', height: '80px' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1rem', color: '#6682ff' }}>{checkIn} </Typography>
            {/*<Typography style={{ fontSize: '1rem' }}>/{bookingsData && bookingsData.total} </Typography>*/}
          </div>
        )}
        {/*{checkOut ? <Typography style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>Out: {checkOut} </Typography> : null}*/}
      </div>
    ) : (
      <div onClick={() => onSelect(x)}>
        {checkIn > -1 && (
          <Typography
            style={{ color: '#6682ff', fontWeight: 'bold', fontSize: '0.7rem', height: '60px', minWidth: '30px' }}
          >
            {checkIn}{' '}
          </Typography>
        )}
      </div>
    );
  };

  const handleMonthRender = (x: any) => {
    const monthCheckIn = getMonthCheckIn(x);
    // const monthCheckOut = getMonthCheckOut(x);
    return (
      <ul className="notes-month">
        {monthCheckIn > -1 ? <li style={{ fontWeight: 'bold', fontSize: '0.6rem' }}> {monthCheckIn} </li> : ''}
      </ul>
    );
  };

  const showEditModalClose = () => {
    setEditModal(false);
  };
  const showEditModalOpen = () => {
    setEditModal(true);
  };

  const Submitted = () => {
    getMow();
    setEditModal(false);
  };

  const handleNavigate = () => {
    navigate('/addNewBooking');
  };
  const handleNavigateQuickBook = () => {
    navigate('/addNewQuickBook');
  };

  const updateTodaysBooking = (x: any) => {
    const today = moment().format('YYYY-MM-DD');
    setLoadingTodayBooking(true);
    const check = todaysBookingData && todaysBookingData.date.split('T')[0] === today;

    let payload = {};
    if (check && todaysBookingData && todaysBookingData.inactive) {
      payload = { _id: todaysBookingData._id, inactive: false };
    } else {
      payload = { date: today, inactive: x };
    }

    dispatch(doUpdateTodaysBooking(payload))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setLoadingTodayBooking(false);
        getTodaysBookingData();
      });
  };

  return (
    <div>
      <PageTitle>Schedule</PageTitle>
      <Card
        title={
          <div
            style={
              isTablet
                ? { display: 'flex', justifyContent: 'space-between' }
                : { display: 'flex', flexDirection: 'column' }
            }
          >
            <Typography style={{ fontWeight: 'bold' }}>
              Total Slots:{' '}
              {loading ? (
                <Spin />
              ) : (
                bookingsData && (
                  <>
                    {totalSlots}
                    {UserData && UserData.type === 'admin' && (
                      <Button
                        size={'small'}
                        style={{ textDecoration: 'none', marginLeft: '5px' }}
                        icon={<EditOutlined />}
                        onClick={showEditModalOpen}
                      />
                    )}
                  </>
                )
              )}
            </Typography>
            <div style={isTablet ? { marginTop: '0px' } : { marginTop: '10px' }}>
              <div
                style={
                  isTablet
                    ? { display: 'flex', flexDirection: 'row' }
                    : { display: 'flex', flexDirection: 'column', justifyContent: 'center' }
                }
              >
                <div style={{ textAlign: 'center' }}>
                  <Button size={isTablet ? 'large' : 'small'} icon={<PlusOutlined />} onClick={handleNavigate}>
                    Add Booking
                  </Button>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    style={isTablet ? { marginLeft: '5px' } : { marginTop: '5px' }}
                    size={isTablet ? 'large' : 'small'}
                    icon={<PlusOutlined />}
                    disabled={todaysBookingData && todaysBookingData.inactive ? true : false}
                    onClick={handleNavigateQuickBook}
                  >
                    Add Quick Booking
                  </Button>
                </div>
              </div>
              {UserData && UserData.type === 'admin' && (
                <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Typography style={{ marginRight: '5px' }}>{`Stop Today's Booking`} </Typography>
                  <Switch
                    disabled={loadingTodayBooking}
                    loading={loadingTodayBooking}
                    onChange={(x) => updateTodaysBooking(x)}
                    defaultChecked={todaysBookingData && todaysBookingData.inactive ? true : false}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                  />
                </div>
              )}
            </div>
          </div>
        }
      >
        <S.Calendars
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.monthsShort(current));
            }

            for (let index = start; index < end; index++) {
              monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                  {months[index]}
                </Select.Option>,
              );
            }
            const month = value.month();

            const year = value.year();
            const options = [];
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>,
              );
            }
            return (
              <div style={{ padding: 8, textAlign: 'right' }}>
                {/*<Typography.Title level={4}>Custom header</Typography.Title>*/}
                <Row gutter={8}>
                  <Col>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      onChange={(newYear: any) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                      value={String(year)}
                    >
                      {options}
                    </Select>
                  </Col>
                  {/*<Col>*/}
                  {/*  <Radio.Group size="small" onChange={(e) => onTypeChange(e.target.value)} value={type}>*/}
                  {/*    <Radio.Button value="month">Month</Radio.Button>*/}
                  {/*    <Radio.Button value="year">Year</Radio.Button>*/}
                  {/*  </Radio.Group>*/}
                  {/*</Col>*/}
                  <Col>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      value={String(month)}
                      onChange={(selectedMonth) => {
                        const newValue = value.clone();
                        newValue.month(parseInt(selectedMonth, 10));
                        onChange(newValue);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                </Row>
              </div>
            );
          }}
          value={date}
          style={{ fontSize: '0.8rem', fontWeight: 'normal' }}
          onChange={setDate}
          onPanelChange={handlePanelChange}
          dateCellRender={handleCellRender}
          monthCellRender={handleMonthRender}
          // disabledDate={(current) => {
          //   return current && current < moment().add(1, 'month');
          // }}
        />
      </Card>
      <Modal
        style={{ zIndex: '999' }}
        footer={[]}
        title="Update Total Slots"
        open={editModal}
        destroyOnClose={true}
        maskClosable={false}
        onOk={showEditModalClose}
        onCancel={showEditModalClose}
      >
        <EditSlotsForm record={totalSlots} submitted={() => Submitted()} />
      </Modal>
    </div>
  );
}

export default App;
